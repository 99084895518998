/* make variables */
:root {
  --blue: #070738;
  --blue-hover: #292934;
  --white: #f5f5f8;
  --green: #48ce84;
  --grey: rgb(190, 186, 186);
  --card1-blue: linear-gradient(to right, #1076f3, #6c0df1);
  --card2-teal: linear-gradient(to right, #10f3c2, #0de6f1);
  --card3-green: linear-gradient(to right, #aff310, #42f10d);
  --card1hover-blue: linear-gradient(to right,#6c0df1, #1076f3);
  --card2hover-teal: linear-gradient(to right,#0de6f1, #10f3c2);
  --card3hover-green: linear-gradient(to right, #42f10d,#aff310);

}

body{
  font-family: 'Montserrat', sans-serif;
}

.interviewContainer{
  padding-top: 7rem !important;
}