/* Header.css */

/* Navbar Styling */
.navbar {
  background-color: white;
  /* White background */
  border-bottom: 1px solid #eaeaea;
  /* Light border at the bottom for separation */
  padding: 5px 30px;
  /* Decrease padding to reduce header height */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  /* Subtle shadow for depth */
  min-height: 70px;
  /* Reduced header height */
  height: 7rem;
  align-items: center;
  /* Vertically center the content */
}

/* Header.css */

/* Center the links in the header */
.navbar-nav {
  display: flex;
  justify-content: center;
  font-size: 17px;
  width: 100%;
  gap: 30px;
  /* Adjust the gap to increase space between links */
}

/* Normal link styling */
.nav-link {
  color: black;
}

/* Hover effect */
.nav-link:hover {
  border-bottom: 2px solid #6c63ff;
  padding-bottom: 2px;
}

/* Active link styling */
.nav-link.active-link {
  border-bottom: 2px solid #6c63ff;
  padding-bottom: 2px;
}

/* Initial state: Background matching the hero section */
.navbar.hero-bg {
  background-color: #f9f9f9;
  ;
  /* Example: Replace with your Hero section color */
  border-bottom: none;
  transition: background-color 0.3s ease-in-out;
  box-shadow: none;
  padding-left: 2rem;
}

/* State when scrolled: Background becomes white */
.navbar.scrolled {
  background-color: white;
  /* White background when scrolled */
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  /* Subtle shadow when scrolled */
  padding-left: 2rem;
}

/* Logo */
.header-logo {
  height: 150px;
  width: auto;
  margin-right: 10px;
}

.header-logo:hover {
  cursor: pointer;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand b {
  font-size: 1.5rem;
  color: #6c63ff;
}

.navbar-toggler {
  border: none;
}


/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
}

.form-control {
  width: 250px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  height: 38px;
  padding: 6px 12px;
  font-size: 0.9rem;
}

.form-control:focus {
  box-shadow: none;
  border-color: #6c63ff;
}

.dropdown-toggle {
  font-weight: bold;
  font-size: 1.2rem;
  background-color: #6c63ff !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.dropdown-toggle:hover {
  background-color: #5a54d1 !important;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  border-radius: 8px;
  padding: 0;
  min-width: 80px;
}

/* Logout Button Styling */
.dropdown-menu .dropdown-item {
  font-weight: bold;
  color: white !important;
  /* Custom color for logout */
  padding: 8px 14px;
  text-align: center;
  background: #6c63ff !important;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  border: none;
  /* Remove any border */
}

.dropdown-item:hover {
  color: #fff !important;
  background-color: #5a54d1 !important;
  /* Consistent hover/focus/active color */
  outline: none;
  /* Remove outline */
  box-shadow: none;
  /* Remove any default Bootstrap shadow */
}

/* Prevent grey color on blur */
.dropdown-item:not(:hover):not(:focus):not(:active) {
  background-color: #6c63ff !important;
  color: white !important;
}

.login-options-dropdown {
  position: absolute;
  top: 100%;
  right: 15px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 250px;
}

.btn {
  background: #6c63ff;
  color: white;
  padding: 6px 20px;
  /* Adjusted padding */
}

.btn:hover {
  background: #5a54d1;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  /* Stack navbar items vertically */
  .navbar-nav {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  /* Center align the brand and links */
  .navbar-brand,
  .nav-item {
    text-align: center;
  }

  /* Full-width login button */
  .btn-outline-primary {
    width: 100%;
    margin-top: 10px;
  }

  /* Adjust the logo size for smaller screens */
  .header-logo {
    height: 80px;
    width: auto;
  }

  /* Expandable search bar to full width */
  .form-control {
    width: 100%;
    margin-bottom: 10px;
  }

  /* Dropdown icon adjustments */
  .dropdown-toggle {
    width: 40px;
    height: 40px;
    margin-top: 0;
  }
}