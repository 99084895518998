/* Custom styles for PrepareForInterview */
.accordion-item {
  border: 1px solid #ddd;
}

.accordion-button {
  background-color: #f8f9fa;
  font-weight: bold;
}

.accordion-body {
  font-size: 1rem;
  background-color: #fff;
}

.login-required-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #6c63ff;  /* Accent color for visibility */
  font-weight: bold; /* Bold text */
  margin-top: 20rem;
  text-align: center;
}

.login-required-message span {
  font-size: 1.3rem; /* Increase the font size */
  margin-bottom: 20px; /* Add spacing below the text */
  color: #000000; /* Theme color */
}

.btn2 {
  background: #6c63ff;
  color: white;
  padding: 10px 25px;
  font-size: 25px;
  border: none;
  border-radius: 10px;
  /* Adjusted padding */
}

.btn2:hover {
  background: #5a54d1;
}