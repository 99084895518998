/* JobDetail.css */

/* Top section with the Hero-like background */
.job-detail-hero {
  background-color: #f9f9f9; /* Same color as your Hero section */
  padding: 40px 0; /* Add space to push the content down */
  text-align: center;
  margin-top: 80px;
}

.job-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.company-logo {
  width: 100px; /* Logo size */
  height: auto;
  margin-bottom: 20px;
}

.job-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Company Info */
.company-info {
  margin-bottom: 20px;
  text-align: center;
}

.company-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #555;
}

.company-details {
  font-size: 1rem;
  color: #777;
}

.apply-section-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  border: 1px solid #eaeaea;
}

.apply-section-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.apply-section-card .card-text {
  font-size: 1rem;
  color: #555;
}

.apply-section-card .btn {
  background-color: #6c63ff;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  width: 100%; /* Full width button */
}

.apply-section-card .btn:hover {
  background-color: #5a54d1;
}

.apply-section-card .text-muted {
  font-size: 0.9rem;
  color: #888;
}

/* Main Content Section */
.job-detail-container {
  background-color: #ffffff;
  margin-top: 30px;
}

/* Job Description Styling */
.job-description {
  padding: 20px 0; /* Add space around the description */
}

.job-description p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .job-title {
    font-size: 2rem;
  }

  .apply-section-wrapper {
    margin-top: 20px;
  }

  .company-logo {
    width: 60px;
    height: 60px;
  }
}
