/* Main container for hero */
.hero {
  background-color: #f9f9f9; /* Light background similar to the image */
  padding: 40px 20px; /* Padding around the hero section */
  border-radius: 10px; /* Rounded corners for the box */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05); /* Soft shadow for a cleaner look */
}

/* Filter Box Header */
.filter-box {
  margin: 0 auto; /* Center the filter box */
  max-width: 1200px; /* Maximum width for the filter section */
}

h4 {
  font-size: 2rem; /* Larger font size for the title */
  font-weight: bold;
  color: #333;
}

/* Search Bar Styling */
.search-bar {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control {
  border: none; /* Remove the border */
  border-bottom: 1px solid #6c63ff; /* Add bottom border only */
  border-radius: 0; /* Remove rounded corners */
  width: 80%; /* Control width of input */
  background-color: transparent; /* Transparent background */
  color: #6c63ff; /* Text color */
  font-size: 1.2rem; /* Increase font size */
  padding-left: 10px;
  padding-right: 10px;
  transition: border-color 0.3s ease;
  outline: none; /* Remove default outline */
}

.form-control::placeholder {
  color: #aaa; /* Placeholder color */
  font-size: 1rem;
}

.form-control:focus {
  border-bottom: 1px solid #6c63ff; /* Change border color when focused */
  box-shadow: none; /* Remove box-shadow */
  outline: none; /* Remove outline */
  background-color: transparent;
}

.btn-outline-success {
  background-color: transparent; /* Transparent background */
  color: #6c63ff; /* Match Hero button color */
  border: 2px solid #6c63ff; /* Add border with the same color */
  border-radius: 0; /* Remove rounded corners */
  padding: 10px 20px;
  font-size: 1.2rem; /* Adjust font size */
  height: auto; /* Adjust button height */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-outline-success:hover {
  background-color: #6c63ff; /* Change background on hover */
  color: white; /* Change text color to white on hover */
}


.hero .btn-outline-success {
  background-color: #6c63ff !important; /* Match the Hero button color, use !important to override Bootstrap */
  color: white !important; /* White text */
  border: none !important; /* Remove the border */
  border-radius: 5px; /* Rounded corners */
  padding: 6px 20px; /* Adjusted padding */
  font-size: 0.9rem; /* Font size */
  height: 38px; /* Match the height of the search bar */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Hover Effect */
.hero .btn-outline-success:hover {
  background-color: #5a54d1 !important; /* Darker purple on hover */
  color: white !important; /* Keep text white on hover */
  border: none !important; /* Ensure no border on hover */
}

/* Extra styling for responsiveness */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col {
    margin-bottom: 15px;
  }

  .btn-primary {
    width: 100%; /* Full width button on mobile */
  }
}
