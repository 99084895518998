/* Home.css */

/* Container for two sections */
.container-fluid {
  padding: 20px;
}

/* Left Section (Job Cards) */
.left-section {
  padding-right: 20px;
  padding-left: 5rem;
  margin-top: 5rem;
}

/* Job Card List Styling */
.jobs-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Adds spacing between each card */
}

/* Card Styling */
.card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Soft shadow */
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Hover effect to lift the card */
  border-color: #6c63ff; /* Highlight the border on hover */
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.card-subtitle {
  font-size: 1.1rem;
  color: #888;
  margin-bottom: 12px;
}

.card-text {
  font-size: 1rem;
  color: #333;
  margin-bottom: 6px;
}

/* Labels under job title, like Full-Time, Senior Role, etc. */
.job-label {
  display: inline-block;
  font-size: 0.9rem;
  background-color: #f1f1f1;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 6px;
}

.card .text-muted {
  font-size: 0.85rem;
}

/* Remove underline from all links inside the card */
.card-link {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent elements */
}

.card-link:hover {
  text-decoration: none; /* Ensure no underline on hover */
  color: inherit; /* Inherit color on hover */
}

/* Right Section (Additional Content) */
.right-section {
  padding-left: 20px;
  padding-right: 5rem;
  margin-top: 5rem;
}

.right-content {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.right-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.right-content p {
  font-size: 1rem;
  color: #555;
}

/* General Styling */
h5, h6 {
  margin-bottom: 12px;
}

p {
  margin-bottom: 10px;
}

.posted-time {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 0.9rem;
  color: #6c757d;
  padding: 4px 8px;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .container-fluid {
    padding: 15px;
  }

  .left-section,
  .right-section {
    padding: 0;
  }

  .jobs-list {
    gap: 1rem;
  }

  .card {
    padding: 15px;
  }
}

.no-jobs-message {
  font-size: 1.5rem;
  color: #777;
  text-align: center;
  margin-top: 20px;
}